import React, { useEffect, useState } from 'react';
import './OurStory.css'; // Import the new CSS file
import bp from '../../assets/Our Story - Why ChiaDia/verica-chia-dia-green.png';
import bp2 from '../../assets/Our Story - Why ChiaDia/Our-Mission.png';
import leafs1 from '../../assets/Our Story - Why ChiaDia/leafs1.png';
import SEO from '../../SEO'; 

function OurStory() {
  const [viewBox, setViewBox] = useState("0 -80 500 100");
  const [textSize, setTextSize] = useState("text-[4.6vw]");
  const [marginTop, setMarginTop] = useState("mt-[10%]");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width > 390 && width < 490) {
        setViewBox("0 -80 500 200");
        setTextSize("text-[4.6vw]");
        setMarginTop("mt-[10%]");
      } else if (width >= 490 && width < 758) {
        setViewBox("0 -80 500 290");
        setTextSize("text-[4vw]"); // Adjust text size here
        setMarginTop("mt-[25%]"); // Set margin to 25%
      } else {
        setViewBox("0 -80 500 100");
        setTextSize("text-[4.6vw]"); // Reset to default for other sizes
        setMarginTop("mt-[10%]"); // Reset margin to default
      }
    };

    handleResize(); // Set initial viewBox
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="os min-w-[100vw] h-full">
      <SEO
      title="Our Story - ChiaDia&#174;"
      description="Discover the inspiring story of ChiaDia, from humble beginnings to creating refreshing chia-based drinks that support health and sustainability."
      keywords={['ChiaDia', 'Our Story', 'chia seeds', 'healthy drinks', 'chia-based products']}
      image="https://chiadia.com/images/our-story.jpg"
      url="https://chiadia.com/our-story"
    />

      <div className='mainCont flex flex-col'>
        {/* Top Section with SVG */}
        <div className={`weirdSection flex flex-col md:mt-[10%] ${marginTop} md:mb-[3%]`}>
          <div className="containerSvg flex justify-center">
            <svg 
              viewBox={viewBox} 
              className="w-full h-auto pb-[]"
            >
              <path id="curve" d="M50,50 C150,-90 350,-90 450,50" fill="transparent" stroke="none" />
              <text width="500" className={`font-black md:text-[0.7vw] ${textSize} text-ogGreenText tracking-[0.01vw]`}>
                <textPath href="#curve" startOffset="50%" textAnchor="middle">
                  WE BELIEVE IN THE MAGIC OF CHIA SEEDS.
                </textPath>
              </text>
            </svg>
          </div>
          <div className='w-full md:h-full h-[30vh] flex md:items-center items-end justify-center absolute top'>
            <img
              src={leafs1}
              alt="Eco Friendly"
              className="relative pb-6 object-contain md:w-[13.5%] w-[20%] max-w-full max-h-full bottom-[10vh] bottom-[8%] sm:bottom-[15vh] md:bottom-[19vh]"
            />
          </div>
        </div>

        {/* Middle Section */}
        <div className='middleSection flex md:flex-row flex-col md:pt-[8vh] pb-[3.24vh] px-[8.38vw] space-x-[3.12vw] relative bottom-[5vh] md:bottom-[0px]'>
          <div className='leftSection flex flex-col md:w-[54%] pb-[10%] md:pb-[5%]'>
            <h2 className='text-ogGreenText font-black md:text-[4.5vw] text-[8vw] pb-[0.052vw]'>
              OUR JOURNEY
            </h2>
            <p className={`text-blackishText md:text-[1.47vw] text-[4.1vw] ${textSize} font-[300] leading-[110%] pb-[1.5rem]`}>
              Began when my sister and I discovered the amazing “chia fresca” drink in Mexico. Instantly captivated by its taste and benefits, and motivated by my sister’s struggles with digestion, we knew we had to share this incredible superdrink with the world.
            </p>
            <p className={`text-blackishText md:text-[1.47vw] text-[4.1vw] ${textSize} font-[300] leading-[110%] pb-[1.5rem]`}>
              Following our passion for a healthy lifestyle, we spent a year perfecting the recipe, blending our love for healthy ingredients with a touch of magic, the magic of “chia seeds”.
            </p>
            <p className='font-bold text-ogGreenText w-[85%] md:text-[1.47vw] text-[4.1vw] leading-[110%]'>
              THE RESULT IS A DRINK THAT SUPPORTS WELL-BEING AND OFFERS THE SAME BENEFITS TO OTHERS AS IT DID FOR US.
            </p>
          </div>
          <div className='rightSection md:w-[46%] flex items-center justify-center'>
            <img
              src={bp}
              alt="ImageBottle"
              className="object-cover md:h-[65vh] h-[35vh] rounded-[1.72vw] shadow-custom-shadow"
              style={{ transform: 'matrix(1, 0.05, -0.05, 1, 1, 0)' }}
            />
          </div>
        </div>

        {/* Bottom Section */}
        <div className='bottomSection flex md:flex-row flex-col-reverse items-center justify-center w-full px-[10%]'>
          <div className='bLeft flex-1'>
            <img
              src={bp2}
              alt="ImageBottle"
              className="object-cover md:h-[58vh] md:w-[90%] rounded-[1.72vw] shadow-custom-shadow"
              style={{ transform: 'matrix(1, -0.05, 0.05, 1, 1, 0)' }}
            />
          </div>
          <div className='bRight text-right flex flex-col items-end justify-center flex-1 w-auto pb-[10%]'>
            <h1 className='text-ogGreenText md:text-[4.5vw] text-[8vw] font-black'>
              OUR MISSION
            </h1>
            <h3 className={`text-ogGreenText font-bold md:text-[1.47vw] text-[4.1vw] ${textSize} leading-[110%] md:pb-[1.5rem] pb-[4%]`}>
              TO MAKE YOUR DAY BETTER—BECAUSE EVERYONE DESERVES A GREAT DIA!
            </h3>
            <p className={`text-blackishText md:text-[1.47vw] text-[4.1vw] ${textSize} font-[300] leading-[110%]`}>
              We’re all about boosting your life with drinks that do more than refresh. With top-notch ingredients and a focus on sustainability, every bottle of ChiaDia is a win for you and the planet!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurStory;
