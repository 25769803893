import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import lgDia from '../../assets/navbar/chia-dia-web-logos/chia-dia.svg';

function MobileNavbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogoClick = () => {
    window.scrollTo(0, 0);
    navigate('/');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = (path) => {
    setMenuOpen(false); // Close menu after navigation
    navigate(path);
  };

  const handleContactClick = () => {
    setMenuOpen(false); // Close menu
    navigate('/'); // Navigate to home
    setTimeout(() => {
      localStorage.setItem('scrollToContact', 'true'); // Set the flag to scroll
      // Scroll to the contact section after a brief delay
      window.location.hash = '#contact-section'; // This ensures the page goes to the contact section
    }, 100); // Delay to allow navigation
  };

  return (
    <div className="w-full z-[99999] ">
      {/* Navbar container */}
      <div className="fixed top-0 left-0 w-full h-[80px] flex items-center justify-between bg-ogGreenBg z-[99999] px-4 pt-[2%]">
        {/* Logo on the left */}
        <div className="logo cursor-pointer" onClick={handleLogoClick}>
          <img src={lgDia} alt="Logo" className="w-[120px]" />
        </div>
        {/* Hamburger icon on the right */}
        <div className="hamburger cursor-pointer" onClick={toggleMenu}>
          <div className="w-[30px] h-[3px] bg-newWhiteishText mb-[5px]"></div>
          <div className="w-[30px] h-[3px] bg-newWhiteishText mb-[5px]"></div>
          <div className="w-[30px] h-[3px] bg-newWhiteishText"></div>
        </div>
      </div>

      {/* Full-screen menu */}
      {menuOpen && (
        <div
          className={`fixed top-0 left-0 w-full h-full z-[100000] flex flex-col items-center justify-center transition-opacity duration-300 ${
            menuOpen ? '' : 'opacity-40'
          }`}
          style={{
            background: `#4CAC57`,
            backdropFilter: 'blur(86px)',
            WebkitBackdropFilter: 'blur(86px)',
          }}
        >
          {/* Close menu (X) */}
          <div className="absolute top-[3%] right-[5%] cursor-pointer" onClick={toggleMenu}>
            <div className="text-3xl text-newWhiteishText font-black">X</div>
          </div>
          {/* Logo at the top left */}
          <div className="absolute top-[1.6%] left-[4%] cursor-pointer" onClick={handleLogoClick}>
            <img src={lgDia} alt="Logo" className="w-[120px]" />
          </div>

          {/* Centered Links */}
          <nav className="flex flex-col items-center space-y-[20%] text-xl text-white">
            <a onClick={() => handleLinkClick('/OurStory')} className="cursor-pointer text-newWhiteishText font-black text-[11vw] hover:text-gray-200">
              Our Story
            </a>
            <a onClick={() => handleLinkClick('/WhyChiadia')} className="cursor-pointer text-newWhiteishText font-black text-[11vw] hover:text-gray-200">
              Why ChiaDia
            </a>
            <a onClick={() => handleLinkClick('/blog')} className="cursor-pointer text-newWhiteishText font-black text-[11vw] hover:text-gray-200">
              Blog
            </a>
            <a onClick={handleContactClick} className="cursor-pointer text-newWhiteishText font-black text-[11vw] hover:text-gray-200">
              Contact
            </a>
          </nav>
        </div>
      )}
    </div>
  );
}

export default MobileNavbar;
