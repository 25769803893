import React, { useState, useEffect, useRef } from 'react';
import './styles/hero.css';
import heroAnimation from '../../assets/hero/finalHero.mp4';

function Hero() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const loadingTimeoutRef = useRef(null);
  const videoTimeoutRef = useRef(null);
  const videoElementRef = useRef(null); // Ref to store the video element

  useEffect(() => {
    // Create and store the video element in the ref
    videoElementRef.current = document.createElement('video');
    videoElementRef.current.src = heroAnimation;
    videoElementRef.current.preload = 'auto';
    videoElementRef.current.muted = true;

    // Video onloadeddata event to hide loading screen when video is ready
    videoElementRef.current.onloadeddata = () => {
      clearTimeout(videoTimeoutRef.current); // Clear the timeout if the video loads before 3 seconds
      setIsLoading(false); // Hide loading screen immediately when video is loaded
    };

    // Load the video data
    videoElementRef.current.load();

    // Simulate loading progress
    setLoadingProgress(0);
    loadingTimeoutRef.current = setInterval(() => {
      setLoadingProgress((prev) => {
        if (prev >= 100) {
          clearInterval(loadingTimeoutRef.current);
          return 100;
        }
        return prev + 3.33;
      });
    }, 20);

    // Set loading timeout for 3 seconds
    videoTimeoutRef.current = setTimeout(() => {
      // Only hide loading screen if video hasn't loaded yet
      if (isLoading) {
        setIsLoading(false); // Hide loading screen after 3 seconds
        clearInterval(loadingTimeoutRef.current); // Clear loading progress
      }
    }, 3000);

    // Disable scrolling
    document.body.style.overflow = 'hidden';

    // Clear intervals on cleanup
    return () => {
      clearInterval(loadingTimeoutRef.current);
      clearTimeout(videoTimeoutRef.current);
      document.body.style.overflow = ''; // Re-enable scrolling
    };
  }, [isLoading]);

  return (
    <div className='w-full h-fit relative'>
      {isLoading && (
        <div className='loadingScreen fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 backdrop-blur-md z-[99999]'>
          <div className='w-1/4 h-8 bg-gray-300 rounded-full overflow-hidden'>
            <div
              className='h-full bg-[#4CAC57] transition-all duration-100'
              style={{ width: `${loadingProgress}%` }}
            />
          </div>
          <p className='text-white text-lg mt-2'>{Math.round(loadingProgress)}%</p>
        </div>
      )}

      {!isLoading && (
        <video
          className='videoBackground relative w-full h-full object-cover'
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
        >
          <source src={heroAnimation} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
}

export default Hero;
